export interface PaymentComponentProps extends LooseObject {}

export interface LooseObject {
	[key: string]: any;
}

export interface FormState {
	touched: boolean;
	valid: boolean;
}
export interface PaymentComponentState {
	amount: string;
	customAmount: boolean;
	amountList: string[];
	frequency: PAY_FREQUENCY;
	currency: string;
	indianCitizen: boolean;
	paymentProcessing: boolean;
	paymentStatus: string;
	failureReason: string;
	userDetails: {
		firstName: string;
		lastName: string;
		email: string;
		phone: string;
		pan: string;
		passport: string;
		address: string;
	};
	detailsFormState: {
		firstName: FormState;
		lastName: FormState;
		email: FormState;
		phone: FormState;
		pan: FormState;
		passport: FormState;
		address: FormState;
	};
}

export enum PAY_FREQUENCY {
	ONCE = 'once',
	MONTHLY = 'monthly',
	ANNUAL = 'yearly',
}
