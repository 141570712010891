import './index.scss';

import { browserName, browserVersion, deviceType } from 'react-device-detect';
import {
	useSearchParams,
	useLocation,
	Outlet,
	Routes,
	Route,
	useNavigate,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { LooseObject } from '../../components/payment/types';
import config from '../../config';
import Header from '../../components/header';
import { signInTabParams } from '../../utils/scriptUtils';

const InterviewSupportPage = (props: any) => {
	const [searchParams] = useSearchParams();
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [profileInfo, setProfileInfo] = useState({});
	const [queryObj, setQueryObj]: [
		LooseObject,
		React.Dispatch<React.SetStateAction<LooseObject>>,
	] = useState({});
	const [metaData, setMetadata] = useState({});
	const [paymentData, setPaymentData]: [
		LooseObject,
		React.Dispatch<React.SetStateAction<LooseObject>>,
	] = useState({});
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const width = window.innerWidth;
		const height = window.innerHeight;

		// Clear specified localStorage keys
		localStorage.removeItem('SSO_TOKEN');
		localStorage.removeItem('SSO_PROFILE_INFO');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('userId');
		
		console.log('LocalStorage cleared');

		setTimeout(() => {
			if ((width ? width : 0) < 600) {
				executeScroll();
			}
		}, 2500);

		let qObj: LooseObject = {};
		searchParams.forEach((v, k) => (qObj[k] = v));
		setQueryObj(qObj);

		if (!!localStorage.getItem('SSO_TOKEN')) {
			const ssoProfileInfo = localStorage.getItem('SSO_PROFILE_INFO');
			setProfileInfo(JSON.parse(ssoProfileInfo ? ssoProfileInfo : ''));
		}
		setLoggedIn(!!localStorage.getItem('SSO_TOKEN'));
		const pathName = qObj.pathname ? qObj.pathname : '';
		setMetadata({
			deviceHeight: height || 0,
			deviceWidth: width || 0,
			isLoggedIn,
			browserVersion,
			browserName,
			deviceType,
			url: config.url,
			path: qObj.iframe === '1' ? pathName : location.pathname,
		});
	}, []);

	const executeScroll = () => {
		document
			.getElementsByClassName('supportPage_content_payment_header')[0]
			.scrollIntoView(true);
	};

	const showSignInPage = (e: React.MouseEvent) => {
		e.preventDefault();

		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			event: 'login_click',
			usertype: 'logged out',
			userID: queryObj.userId || null,
		});
		const signInWindow: any = window.open(
			config.header.sso.signIn,
			'exportPopup',
			signInTabParams,
		);
		var timer = setInterval(async () => {
			const token = localStorage.getItem('SSO_TOKEN');
			let userId = localStorage.getItem('userId');
			if (!token) return;
			clearInterval(timer);
			setLoggedIn(true);
			setMetadata({
				...metaData,
				isLoggedIn: true,
			});
			let ssoProfileInfo: any = localStorage.getItem('SSO_PROFILE_INFO');
			ssoProfileInfo = JSON.parse(ssoProfileInfo);
			setProfileInfo(ssoProfileInfo);
			signInWindow.close();
			window.dataLayer.push({
				event: 'login_click',
				usertype: 'logged in',
				userID: userId,
			});
			setQueryObj({ ...queryObj, userId });
		}, 500);
	};

	const handlePaymentSuccess = (pmtData: any) => {
		setPaymentData(pmtData);
		navigate('thank-you');
	};

	const FullPage = () => {
		return (
			<div className="supportPage">
				<div className="supportPage_welcomeBanner">
					<div className="supportPage_welcomeBanner_logo">
						<a title="The Wire - Home" href="https://thewire.in">
							<img
								src="https://cdn.thewire.in/wp-content/uploads/thewire-app-images/wire-logo.svg"
								alt="The Wire Logo"
							/>
						</a>
					</div>
					<div className="supportPage_welcomeBanner_text">
						Support The Interview with Karan Thapar
					</div>
				</div>
				<div className="supportPage_content">
					<div className="supportPage_content_info">
						<div className="supportPage_content_info_text">
							<span className="boldText">
								The Interview with Karan Thapar
							</span>{' '}
							is The Wire’s flagship English language show, where
							you have seen Karan Thapar ask most the incisive
							questions and get the answers that matter. The
							guests on the show have included Nobel Laureates,
							leading scientists and intellectuals, key political
							figures and many more.
							<br />
							<br />
							Over the last one year, this show, which you have
							watched and loved, has garnered more than{' '}
							<span className="boldText">10 million views</span>,
							a testament to its popularity.
							<br />
							<br />
							Now, this show enters a new phase, in a more
							interactive avatar. We seek your support in making{' '}
							<span className="boldText">
								The Interview with Karan Thapar
							</span>{' '}
							to most successful community-funded show in India.
							We are reimagining how news is presented and funded.
							Join us on this journey in creating high-quality
							independent journalism
						</div>
					</div>
					<div className="supportPage_content_payment">
						{paymentData.amount ? (
							<div className="supportPage_content_payment_header redText">
								Thank you!
							</div>
						) : (
							''
							// isLoggedIn || (
							// 	<div className="supportPage_content_payment_header">
							// 		Already a contributor?{' '}
							// 		<a href="#" onClick={showSignInPage}>
							// 			Login
							// 		</a>
							// 	</div>
							// )
						)}
						<Outlet
							context={{
								query: queryObj,
								profileData: profileInfo,
								metaData: metaData,
								onSuccess: handlePaymentSuccess,
								paymentData,
								frequency: { once: true, monthly: true },
								amountListOnce: ['499', '999', '1999'],
								amountListMonthly: ['199', '249', '399'],
								amountListIntl: ['10', '50', '100'],
								formLabel:
									'I would like to support The Interview by Karan Thapar',
								hideCheckBox: true,
								panOptional: true,
								hideTaxReceipt: true,
							}}
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			{queryObj.iframe === '1' || <Header isLoggedIn={isLoggedIn} />}
			<div className="supportPage">
				<FullPage />
			</div>
		</div>
	);
};

export default InterviewSupportPage;
