import React, { useState } from 'react';
import Header from '../../components/header';
import {
	SSOCancelSubscription,
	SSOFetchRpaySubscriptionDetails,
} from '../../utils/ssoapi';
import Loader from 'react-spinners/PuffLoader';
import './index.scss';
import LoadingOverlay from 'react-loading-overlay-ts';

const ManageSubscriptionPage = () => {
	const [subId, setSubId] = useState('');
	const [subDetails, setSubDetails] = useState<any>(null);
	const [fetchError, setFetchError] = useState<any>(null);
	const [cancelError, setCancelError] = useState<any>(null);
	const [loading, setLoading] = useState(false);

	const fetchHandler = async () => {
		setLoading(true);
		try {
			const res = await SSOFetchRpaySubscriptionDetails(subId);
			setSubDetails(res.data);
			setFetchError(null);
		} catch (e: any) {
			setSubDetails(null);
			setFetchError(e.response.data);
		} finally {
			setLoading(false);
		}
	};

	const subIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSubId(e.target.value);
	};

	const cancelHandler = async () => {
		if (
			!window?.confirm(
				`Cancelling subscription with id ${subId}. Are you sure?`,
			)
		)
			return;

		setLoading(true);
		try {
			await SSOCancelSubscription(subId);
			await fetchHandler();
			setCancelError(null);
		} catch (e: any) {
			setCancelError(e.response.data);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="manageSubPage">
			<Header isLoggedIn={false} />
			<LoadingOverlay
				active={loading}
				spinner={<Loader color={'#1aa1df'} size={50} />}
			>
				<div className="supportPage_welcomeBanner">
					<div className="supportPage_welcomeBanner_logo">
						<a title="The Wire - Home" href="https://thewire.in">
							<img
								src="https://cdn.thewire.in/wp-content/uploads/thewire-app-images/wire-logo.svg"
								alt="The Wire Logo"
							/>
						</a>
					</div>
					<div className="supportPage_welcomeBanner_text">
						Enter your Razorpay subscription id below
					</div>
				</div>
				<div className="manageSubPage_content">
					<input
						onChange={subIdHandler}
						value={subId}
						placeholder="Subscription ID*"
						required
					></input>
					<div className="continueBtnContainer fetchSubscription">
						<button
							className="continue-button"
							disabled={!!!subId}
							onClick={fetchHandler}
						>
							<span>Fetch Details</span>
							<i className="arrow-right-icon-white"></i>
						</button>
					</div>
					{subDetails && (
						<div>
							<div className="subscriptionDetailsContainer">
								<div>
									<span>Subscription Details:</span> INR{' '}
									{subDetails.planDetails?.name.split('_')[0]}{' '}
									{subDetails.planDetails?.name.split('_')[1]}
								</div>
								<div>
									<span>Status:</span> {subDetails.status}
								</div>
								<div>
									<span>Subscribed on:</span>{' '}
									{new Date(
										subDetails.created_at * 1000,
									).toDateString()}
								</div>
								<div>
									<span>Next Due:</span>{' '}
									{subDetails.charge_at
										? new Date(
												subDetails.charge_at * 1000,
										  ).toDateString()
										: 'N/A'}
								</div>
								<div>
									<span>Razorpay Link:</span>{' '}
									<a
										href={subDetails.short_url}
										target={'_blank'}
									>
										{subDetails.short_url}
									</a>
								</div>
							</div>
							{subDetails.status === 'cancelled' || (
								<div className="continueBtnContainer cancelSubscription">
									<button
										className="continue-button"
										onClick={cancelHandler}
									>
										<span>Cancel Subscription</span>
										<i className="arrow-right-icon-white"></i>
									</button>
								</div>
							)}
						</div>
					)}
					{fetchError && (
						<div>
							Failed to fetch subscription details. Please try
							again.
						</div>
					)}
					{cancelError && (
						<div>
							Failed to cancel subscription. Please try again.
						</div>
					)}
				</div>
			</LoadingOverlay>
		</div>
	);
};

export default ManageSubscriptionPage;
