import axios from 'axios';
import { LooseObject } from '../components/payment/types';
import config from '../config';

const updateToken = (
	result: any,
	refreshToken: string,
	accessToken: string,
) => {
	const newAccessToken = result.headers['access-token'];
	const newRefreshToken = result.headers['refresh-token'];
	if (
		newAccessToken &&
		newRefreshToken &&
		newAccessToken !== accessToken &&
		refreshToken !== newRefreshToken
	) {
		localStorage.setItem('SSO_TOKEN', newAccessToken);
		localStorage.setItem('refreshToken', newRefreshToken);
	}
};

const handle403 = (message: any) => {
	switch (message) {
		case 'Forbidden Access': {
			localStorage.clear();
			window.location.reload();
		}
	}
};

const handleError = (error: any) => {
	const { status, data } = error.response;
	switch (status) {
		case 403:
			return handle403(data.message);
	}
};

const SSOProfileApi = async (token: string) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(config.header.sso.profile, {
			params: { token },
			headers: {
				'Content-Type': 'application/json',
				'refresh-token': refreshToken || '',
			},
		});
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
		throw error;
	}
};

const SSOChangePassword = async (token: string, password: string) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.post(
			config.header.sso.changePassword,
			{ password },
			{
				params: { token },
				headers: {
					'Content-Type': 'application/json',
					'refresh-token': refreshToken || '',
				},
			},
		);
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSOFetchNewsletters = async (token: string, email: string) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(config.header.sso.newsletters, {
			params: { token, email },
			headers: {
				'Content-Type': 'application/json',
				'refresh-token': refreshToken || '',
			},
		});
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSOUnsubscribeNewsletter = async (
	token: string,
	listid: string,
	email: string,
) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(config.header.sso.unsubscribe, {
			params: { token, listid, email },
			headers: {
				'Content-Type': 'application/json',
				'refresh-token': refreshToken || '',
			},
		});
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSOAuthMethods = async (token: string) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(config.header.sso.authMethods, {
			params: { token },
			headers: {
				'Content-Type': 'application/json',
				'refresh-token': refreshToken || '',
			},
		});
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSOPaymentHistory = async (token: string, email: string) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(config.header.sso.paymentHistoryLocal, {
			params: { token, email },
			headers: {
				'Content-Type': 'application/json',
				'refresh-token': refreshToken || '',
			},
		});
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSORefreshPayments = async (token: string, email: string) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(
			config.header.sso.paymentHistoryRefresh,
			{
				params: { token, email },
				headers: {
					'Content-Type': 'application/json',
					'refresh-token': refreshToken || '',
				},
			},
		);
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSOInvoice = async (token: string, paymentId: string) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(config.header.sso.invoice, {
			params: { token, paymentId },
			headers: {
				'Content-Type': 'application/json',
				'refresh-token': refreshToken || '',
			},
		});
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSOInvoiceLocal = async (
	token: string,
	email: string,
	rpay_paymentId: string,
) => {
	const refreshToken = localStorage.getItem('refreshToken');
	try {
		const result = await axios.get(config.header.sso.invoiceLocal, {
			params: { token, email, rpay_paymentId },
			headers: {
				'Content-Type': 'application/json',
				'refresh-token': refreshToken || '',
			},
		});
		updateToken(result, refreshToken || '', token);
		return result;
	} catch (error) {
		handleError(error);
	}
};

const SSOCreateRpayOrder = (
	email: string,
	order: Object,
	notes: LooseObject,
) => {
	return axios.post(config.header.sso.createRpayOrder, order, {
		params: { email, notes },
	});
};

const SSOCreateRpaySubscription = (
	email: string,
	plan: Object,
	notes: LooseObject,
) => {
	return axios.post(config.header.sso.createRpaySubscription, plan, {
		params: { email, notes },
	});
};

const SSOHandlePaymentSuccess = (
	data: Object,
	notes: LooseObject,
	metaData: LooseObject,
) => {
	return axios.post(
		config.header.sso.paymentSuccessHandler,
		{ ...data, metaData },
		{ params: { notes } },
	);
};

const SSOFetchRpaySubscriptionDetails = (subId: string) => {
	return axios.get(
		`${config.header.sso.fetchRpaySubscriptionDetails}/${subId}`,
	);
};

const SSOCancelSubscription = (subId: string) => {
	return axios.post(
		`${config.header.sso.fetchRpaySubscriptionDetails}/${subId}/cancel`,
	);
};

export {
	SSOProfileApi,
	SSOChangePassword,
	SSOFetchNewsletters,
	SSOUnsubscribeNewsletter,
	SSOAuthMethods,
	SSOPaymentHistory,
	SSOInvoice,
	SSORefreshPayments,
	SSOCreateRpayOrder,
	SSOHandlePaymentSuccess,
	SSOCreateRpaySubscription,
	SSOInvoiceLocal,
	SSOFetchRpaySubscriptionDetails,
	SSOCancelSubscription,
};
