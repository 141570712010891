import {Component} from "react"
import { decodeToken } from "react-jwt";
import { SSOProfileApi } from '../../utils/ssoapi';

export default class SignInContainer extends Component {

 useQuery() {
    return new URLSearchParams(window.location.search);
 }


 async componentDidMount() {
    let query = this.useQuery();
    const token = query.get("token");
    const userId = query.get("userId");
    const refreshToken = query.get("refreshToken");
    if (token) {
        const profileInfo = decodeToken(token);
        localStorage.setItem("userId", userId);
        localStorage.setItem("SSO_TOKEN", token);
        localStorage.setItem("SSO_PROFILE_INFO", JSON.stringify(profileInfo));
        localStorage.setItem("refreshToken",refreshToken)
        if (!profileInfo.email){
            const response = await SSOProfileApi(token);
            if (response && response.data)
                localStorage.setItem(
                    'SSO_PROFILE_INFO',
                    JSON.stringify(response.data),
                );
        }
    }
    window.close()
 }

 render() {
    return (
         <div className="tw-container">
             <div className="sign-in-container top-space">
                 
             </div>
         </div>
     )
 }
}
