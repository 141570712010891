import './index.scss';

import { browserName, browserVersion, deviceType } from 'react-device-detect';
import {
	useSearchParams,
	useLocation,
	Outlet,
	Routes,
	Route,
	useNavigate,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Payment } from '../../components/payment';
import { LooseObject } from '../../components/payment/types';
import config from '../../config';
import Header from '../../components/header';
import ThankYouCard from '../../components/thankYouCard';
import { signInTabParams } from '../../utils/scriptUtils';

const SupportPage = (props: any) => {
	const [searchParams] = useSearchParams();
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [profileInfo, setProfileInfo] = useState({});
	const [queryObj, setQueryObj]: [
		LooseObject,
		React.Dispatch<React.SetStateAction<LooseObject>>,
	] = useState({});
	const [metaData, setMetadata] = useState({});
	const [paymentData, setPaymentData]: [
		LooseObject,
		React.Dispatch<React.SetStateAction<LooseObject>>,
	] = useState({});
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const width = window.innerWidth;
		const height = window.innerHeight;

    // Clear specified localStorage keys
    localStorage.removeItem('SSO_TOKEN');
    localStorage.removeItem('SSO_PROFILE_INFO');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    
    console.log('LocalStorage cleared');

		setTimeout(() => {
			if ((width ? width : 0) < 600) {
				executeScroll();
			}
		}, 2500);

		let qObj: LooseObject = {};
		searchParams.forEach((v, k) => (qObj[k] = v));
		setQueryObj(qObj);

		if (!!localStorage.getItem('SSO_TOKEN')) {
			const ssoProfileInfo = localStorage.getItem('SSO_PROFILE_INFO');
			setProfileInfo(JSON.parse(ssoProfileInfo ? ssoProfileInfo : ''));
		}
		setLoggedIn(!!localStorage.getItem('SSO_TOKEN'));
		const pathName = qObj.pathname ? qObj.pathname : '';
		setMetadata({
			deviceHeight: height || 0,
			deviceWidth: width || 0,
			isLoggedIn,
			browserVersion,
			browserName,
			deviceType,
			url: config.url,
			path: qObj.iframe === '1' ? pathName : location.pathname,
		});
	}, []);

	const executeScroll = () => {
		document
			.getElementsByClassName('supportPage_content_payment_header')[0]
			.scrollIntoView(true);
	};

	const showSignInPage = (e: React.MouseEvent) => {
		e.preventDefault();

		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			event: 'login_click',
			usertype: 'logged out',
			userID: queryObj.userId || null,
		});
		const signInWindow: any = window.open(
			config.header.sso.signIn,
			'exportPopup',
			signInTabParams,
		);
		var timer = setInterval(async () => {
			const token = localStorage.getItem('SSO_TOKEN');
			let userId = localStorage.getItem('userId');
			if (token) {
				clearInterval(timer);
				setLoggedIn(true);
				setMetadata({
					...metaData,
					isLoggedIn: true,
				});
				let ssoProfileInfo: any =
					localStorage.getItem('SSO_PROFILE_INFO');
				setProfileInfo(JSON.parse(ssoProfileInfo));
				signInWindow.close();

				window.dataLayer.push({
					event: 'login_click',
					usertype: 'logged in',
					userID: userId,
				});
				setQueryObj({ ...queryObj, userId });
			}
		}, 500);
	};

	const handlePaymentSuccess = (pmtData: any) => {
		setPaymentData(pmtData);
		navigate('thank-you');
	};

	const FullPage = () => {
		return (
      <div className='supportPage'>
        <div className='supportPage_welcomeBanner'>
          <div className='supportPage_welcomeBanner_logo'>
            <a title='The Wire - Home' href='https://thewire.in'>
              <img
                src='https://cdn.thewire.in/wp-content/uploads/thewire-app-images/wire-logo.svg'
                alt='The Wire Logo'
              />
            </a>
          </div>
          <div className='supportPage_welcomeBanner_text'>
            Support Free & Independent Journalism
          </div>
        </div>
        <div className='supportPage_content'>
          <div className='supportPage_content_info'>
            <div className='supportPage_content_info_video'>
              <iframe
                className='supportPage_content_info_video_iframe'
                src='https://www.youtube.com/embed/GCe4CuwciyY?mute=1'
              ></iframe>
            </div>
            <div className='supportPage_content_info_text'>
              The founding premise of The Wire is this: if good journalism is to
              survive and thrive, it can only do so by being both editorially
              and financially independent. This means relying principally on
              contributions from readers and concerned citizens who have no
              interest other than to sustain a space for quality journalism.
            </div>
            <div className='supportPage_content_info_header'>
              <span className='supportPage_content_info_header_text'>
                Make A Direct Donation
              </span>
              <div className='supportPage_content_info_header_line'></div>
            </div>
            <div className='supportPage_content_info_text'>
              Account Name:{' '}
              <span className='boldText'>
                Foundation for Independent Journalism{' '}
              </span>
              <br></br>
              Bank: <span className='boldText'>HDFC Bank Ltd.</span>
              <br></br>
              Branch:{' '}
              <span className='boldText'>
                Jorbagh Market, New Delhi, India{' '}
              </span>
              <br></br>
              Type of Account: <span className='boldText'>Current</span>
              <br></br>
              Account Number: <span className='boldText'>50200015005418</span>
              <br></br>
              IFSC: <span className='boldText'>HDFC0000617 </span>
              <div style={{ marginTop: '2rem' }}>
                While making a direct donation please share your Name and PAN
                details.
                <br></br>
                This is necessary for your Tax Receipt.
                <br></br>
                Only Indian citizens can make a contribution to The Wire
              </div>
            </div>
            <div className='supportPage_content_info_header'>
              <span className='supportPage_content_info_header_text'>
                Got Questions?
              </span>
              <div className='supportPage_content_info_header_line'></div>
            </div>
            <div className='supportPage_content_info_text'>
              {/* Check out our{' '}
              <span className='boldText'>
                <a href='https://thewire.in/donationfaq'>Support Plan FAQ</a>
              </span> */}
              If you have any specific query not answered there, then please
              write to us at{' '}
              <span className='boldText'>
                <a href='mailto: accounts@thewire.in'>accounts@thewire.in</a>
              </span>
            </div>
          </div>
          <div className='supportPage_content_payment'>
            {paymentData.amount ? (
              <div className='supportPage_content_payment_header redText'>
                Thank you!
              </div>
            ) : (
              ''
              // isLoggedIn || (
              //   <div className='supportPage_content_payment_header'>
              //     Already a contributor?{' '}
              //     <a href='#' onClick={showSignInPage}>
              //       Login
              //     </a>
              //   </div>
              // )
            )}
            <Routes>
              <Route
                path=''
                element={
                  <Payment
                    query={queryObj}
                    profileData={profileInfo}
                    metaData={metaData}
                    frequency={{
                      once: true,
                      monthly: true,
                      yearly: true,
                    }}
                    amountListOnce={['2500', '5000', '10000']}
                    amountListMonthly={['200', '300', '500']}
                    amountListAnnual={['1000', '2500', '5000']}
                    amountListIntl={['100', '200', '500']}
                    formLabel='I would like to contribute'
                    onSuccess={handlePaymentSuccess}
                  />
                }
              />
              <Route
                path='thank-you'
                element={<ThankYouCard paymentData={paymentData} />}
              />
              <Route
                path='contribute/thank-you'
                element={<ThankYouCard paymentData={paymentData} />}
              />
            </Routes>
            <Outlet />
          </div>
        </div>
      </div>
    );
	};

	const PaymentIframe = (props: { metaData: LooseObject }) => {
		return (
      <div className='supportPage_content_payment_iframe'>
        <Payment
          query={queryObj}
          profileData={profileInfo}
          metaData={props.metaData}
          frequency={{ once: true, monthly: true, yearly: true }}
          amountListOnce={['2500', '5000', '10000']}
          amountListMonthly={['200', '300', '500']}
          amountListAnnual={['1000', '2500', '5000']}
          amountListIntl={['100', '200', '500']}
          formLabel='I would like to contribute'
        />
      </div>
    );
	};

	return (
		<div>
			{/* {queryObj.iframe === '1' || <Header isLoggedIn={isLoggedIn} />} */}
			<div className="supportPage">
				{queryObj.iframe !== '1' ? (
					<FullPage />
				) : (
					<PaymentIframe metaData={metaData} />
				)}
			</div>
		</div>
	);
};

export default SupportPage;
