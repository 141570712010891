import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SupportPage from './pages/SupportPage/component';
import SignInContainer from './components/signinContainer/SignInContainer';
import ManageSubscriptionPage from './pages/ManageSubscriptionPage';
import InterviewSupportPage from './pages/InterviewSupportPage/component';
import { Payment } from './components/payment';
import ThankYouCard from './components/thankYouCard';
import OutletContextToPropsHOC from './components/OutletContextToPropsHOC';
import { useEffect } from 'react';
import config from './config';
import { SSOProfileApi } from './utils/ssoapi';
import { signOutTabParams } from './utils/scriptUtils';

const AppRouter = () => {
	useEffect(() => {
		const token = localStorage.getItem('SSO_TOKEN');
		if (token) {
			SSOProfileApi(token)
				.then((response) => {
					if (response && response.data)
						localStorage.setItem(
							'SSO_PROFILE_INFO',
							JSON.stringify(response.data),
						);
				})
				.catch((err) => {});
			return;
		}
		let cookie: any = document.cookie
			.split(';')
			.map((cookie) => cookie.split('='))
			.reduce(
				(a, [k, v]) => ({ ...a, [k.trim()]: decodeURIComponent(v) }),
				{},
			);
		const { browserId } = cookie;
		if (!browserId) {
			localStorage.clear();
			return;
		}
		const signInWindow: any = window.open(
			config.header.sso.signInCheck,
			'exportPopup',
			signOutTabParams,
		);
		var timer = setTimeout(async () => {
			const token = localStorage.getItem('SSO_TOKEN');
			if (token) {
				SSOProfileApi(token)
					.then((response) => {
						if (response && response.data)
							localStorage.setItem(
								'SSO_PROFILE_INFO',
								JSON.stringify(response.data),
							);
					})
					.catch((err) => {});
				clearInterval(timer);
				signInWindow.close();
				window.location.reload();
			}
		}, 500);
	}, []);
	return (
		<BrowserRouter>
			{/* <App /> */}
			<Routes>
				{/* <Route path="/iframe/signIn" element={<App />} /> */}
				<Route path="" element={<SupportPage />} />
				<Route path="contribute" element={<SupportPage />} />
				<Route
					path="subscription"
					element={<ManageSubscriptionPage />}
				/>
				<Route path="the-interview" element={<InterviewSupportPage />}>
					<Route
						index
						element={<OutletContextToPropsHOC element={Payment} />}
					/>
					<Route
						path="thank-you"
						element={
							<OutletContextToPropsHOC element={ThankYouCard} />
						}
					/>
				</Route>
				<Route path="callback" element={<SignInContainer />} />
				<Route path="*" element={<SupportPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRouter;
