import {
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
} from '@coreui/react';
import './index.scss';
import '@coreui/coreui/dist/css/coreui.min.css';
import { HeaderComponentProps } from './types';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import config from '../../config';
import { signOutTabParams } from '../../utils/scriptUtils';

const Header = (props: HeaderComponentProps) => {
	const location = useLocation();
	const [userId, setUserId]: [
		string,
		React.Dispatch<React.SetStateAction<string>>,
	] = useState('');

	useEffect(() => {
		location.search === '?logout=true' && logout();
		const userId = localStorage.getItem('userId') || '';
		setUserId(userId);
	}, []);

	const headerLinks = [
		{
			title: 'Who is The Wire',
			link: 'https://thewire.in/about-us',
			target: '_blank',
		},
		{
			title: 'How does The Wire Spend',
			link: 'https://thewire.in/donationfaq',
			target: '_blank',
		},
		{
			title: 'FAQs',
			link: 'https://thewire.in/donationfaq',
			target: '_blank',
		},
		{
			title: 'Check Support Plan',
			link: '/subscription',
			target: '',
		},
		// {
		//   title: "Cancellation & Refunds",
		//   link: "https://support.thewire.in/refund-policy"
		// }
	];

	const logout = () => {
		const signOutWindow = window.open(
			config.header.sso.signout,
			'exportPopup',
			signOutTabParams,
		);
		localStorage.clear();
		setTimeout(() => {
			signOutWindow && signOutWindow.close();
			window.location.search = '';
		}, 1000);
	};

	const onHeaderLinkClick = (linkProps: any) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'top_navigation_click',
			navigation_header: linkProps.title,
			usertype: userId ? 'logged in' : 'logged out',
			userID: userId || null,
		});
	};

	return (
		<div className="headerMain">
			{headerLinks.map((v, i) => (
				<span
					onClick={onHeaderLinkClick.bind(this, v)}
					className="headerLink"
					key={i}
				>
					<a href={v.link} target={v.target}>
						{v.title}
					</a>
				</span>
			))}
			<div className="headerMain_dropDown">
				<CDropdown>
					<CDropdownToggle color="white">Info Links</CDropdownToggle>
					<CDropdownMenu>
						{headerLinks.map((v, i) => (
							<CDropdownItem
								onClick={onHeaderLinkClick}
								href={v.link}
								key={i}
							>
								{v.title}
							</CDropdownItem>
						))}
					</CDropdownMenu>
				</CDropdown>
			</div>
			{props.isLoggedIn && (
				<div className="headerMain_LoggedInContent">
					<span>
						<a onClick={() => logout()}>Logout</a>
					</span>
				</div>
			)}
		</div>
	);
};

export default Header;
