import React, { Component } from 'react';
import { loadScript, roundOffFloat } from '../../utils/scriptUtils';
import './index.scss';
import {
	SSOCreateRpayOrder,
	SSOCreateRpaySubscription,
	SSOHandlePaymentSuccess,
} from '../../utils/ssoapi';
import config from '../../config';
import {
	LooseObject,
	PaymentComponentProps,
	PaymentComponentState,
	PAY_FREQUENCY,
} from './types';
import CurrencyDropdown from '../currencyDropdown';
import axios from 'axios';

const PAYMENT_STATUS = {
	PENDING: 'pending',
	SUCCESS: 'success',
	FAILED: 'failed',
};

export class PaymentComponent extends Component<
	PaymentComponentProps,
	PaymentComponentState
> {
	constructor(props: PaymentComponentProps) {
		super(props);
		this.state = {
			amount: this.props.amountListMonthly[0],
			customAmount: false,
			amountList: this.props.amountListMonthly,
			currency: 'INR',
			frequency: PAY_FREQUENCY.MONTHLY,
			indianCitizen: true,
			paymentProcessing: false,
			paymentStatus: PAYMENT_STATUS.PENDING,
			failureReason: '',
			userDetails: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				pan: '',
				passport: '',
				address: ''
			},
			detailsFormState: {
				firstName: {
					touched: false,
					valid: true,
				},
				lastName: {
					touched: true,
					valid: true,
				},
				email: {
					touched: false,
					valid: true,
				},
				phone: {
					touched: false,
					valid: true,
				},
				pan: {
					touched: false,
					valid: true,
				},
				passport: {
					touched: false,
					valid: true,
				},
				address: {
					touched: false,
					valid: true,
				},
			},
		};
	}

	componentDidMount() {
		const { profileData } = this.props;
		if (profileData) {
			this.setState({
				userDetails: {
					email: profileData.email,
					firstName: profileData.firstName
						? profileData.firstName.trim()
						: '',
					lastName: profileData.lastName
						? profileData.lastName.trim()
						: '',
					phone: '',
					pan: '',
					passport: '',
					address: '',
				},
			});
		}

		// config.ipRegistryKey !== 'null' &&
		// 	axios
		// 		.get(`https://api.ipregistry.co/?key=${config.ipRegistryKey}`)
		// 		.then((response) => {
		// 			const { code } = response.data.currency;
		// 			this.handleDefaultCurrencyChange(code);
		// 		})
		// 		.catch(() => {
		// 			this.handleDefaultCurrencyChange('INR');
		// 		});
	}

	displayRazorpayForm = async () => {
		const res = await loadScript(
			'https://checkout.razorpay.com/v1/checkout.js',
		);

		if (!res) {
			throw new Error('Razorpay SDK failed to load. Are you online?');
		}

		const notes: LooseObject = {};

		if (this.props.query.sachet) {
			notes.sachet = this.props.query.sachet;
		}

		if (this.props.query.utm_term === 'rootbridge') {
			for (let key in this.props.query) {
				if (key.indexOf('utm') === 0) {
					notes[key] = this.props.query[key];
				}
			}
		}

		notes.pan = this.state.userDetails.pan;
		notes.passport = this.state.userDetails.passport;

		let { frequency, amount, currency } = this.state;
		let options =
			frequency === PAY_FREQUENCY.ONCE
				? await this.getOnetimePaymentOptions(notes)
				: await this.getSubscriptionOptions(notes);

		if (options) {
			options = {
				...options,
				modal: {
					ondismiss: function () {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push({
							event: 'contribution_failed',
							payment_method: null,
							cancel_reason: 'form_closed_by_user',
							support_us_duration: frequency,
							currency: currency,
							support_us_amount: amount,
							usertype: localStorage.getItem('userId')
								? 'logged in'
								: 'logged out',
							userID: localStorage.getItem('userId') || null,
						});
					},
				},
			};
			const paymentObject = new (window as any).Razorpay(options);
			paymentObject.open();
			paymentObject.on('payment.failed', ({ error }: { error: any }) => {
				this.setState({
					paymentStatus: PAYMENT_STATUS.FAILED,
					failureReason: error.description,
				});
				window.dataLayer = window.dataLayer || [];
				const userId = localStorage.getItem('userId');
				window.dataLayer.push({
					event: 'contribution_failed',
					payment_method: null,
					cancel_reason: error.reason,
					support_us_duration: this.state.frequency,
					currency: this.state.currency,
					support_us_amount: this.state.amount,
					usertype: userId ? 'logged in' : 'logged out',
					userID: userId || null,
				});
			});
		}
	};

	getSubscriptionOptions = async (
		notes: LooseObject,
	): Promise<LooseObject> => {
		//create a new subscription
		let result;
		try {
			result = await SSOCreateRpaySubscription(
				this.state.userDetails.email,
				{
					amount: roundOffFloat(parseFloat(this.state.amount)),
					currency: this.state.currency,
					period: this.state.frequency,
					interval: 1,
					name: `${this.state.amount}_${this.state.frequency}`,
					description: 'The Wire subscription plan',
				},
				notes,
			);
		} catch (e) {
			alert('Server Error. Are you online?');
			throw new Error('Server Error. Are you online?');
		}

		// Getting the order details back
		const { id: subscription_id } = result.data;
		return {
			key: config.RPAY_KEY_ID,
			subscription_id,
			name: 'The Wire',
			description: 'Recurring donation to The Wire',
			image: 'https://assets.orcsnet.com/img/the-wire-2020-07-09.png',
			handler: async (response: any) => {
				const verificationData = {
					orderCreationId: undefined,
					subscriptionId: subscription_id,
					razorpayPaymentId: response.razorpay_payment_id,
					razorpayOrderId: undefined,
					razorpaySignature: response.razorpay_signature,
				};

				const { data } = await SSOHandlePaymentSuccess(
					{ ...verificationData, ...this.state.userDetails },
					notes,
					this.props.metaData,
				);

				this.setState({ paymentStatus: PAYMENT_STATUS.SUCCESS });

				this.onSuccess();
			},
			prefill: {
				name:
					this.state.userDetails.firstName +
					' ' +
					this.state.userDetails.lastName,
				email: this.state.userDetails.email,
				contact: this.state.userDetails.phone,
			},
			notes: {
				email: this.state.userDetails.email,
				pan: this.state.userDetails.pan,
				passport: this.state.userDetails.passport,
				address: this.state.userDetails.address,
				sub_id: subscription_id,
				...notes,
			},
			theme: {
				color: '#b71c1c',
			},
		};
	};

	getOnetimePaymentOptions = async (
		notes: LooseObject,
	): Promise<LooseObject> => {
		// creating a new order
		let result;
		try {
			result = await SSOCreateRpayOrder(
				this.state.userDetails.email,
				{
					amount: roundOffFloat(parseFloat(this.state.amount)),
					currency: this.state.currency,
				},
				notes,
			);
		} catch (e) {
			alert('Server Error. Are you online?');
			throw new Error('Server error. Are you online?');
		}

		// Getting the order details back
		const { id: order_id, currency } = result.data.orderData;
		return {
			key: config.RPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
			amount: roundOffFloat(parseFloat(this.state.amount)),
			currency,
			name: 'The Wire',
			description: 'One time donation to The Wire',
			image: 'https://assets.orcsnet.com/img/the-wire-2020-07-09.png',
			order_id,
			handler: async (response: any) => {
				const verificationData = {
					orderCreationId: order_id,
					razorpayPaymentId: response.razorpay_payment_id,
					subscriptionId: undefined,
					razorpayOrderId: response.razorpay_order_id,
					razorpaySignature: response.razorpay_signature,
				};

				const { data } = await SSOHandlePaymentSuccess(
					{ ...verificationData, ...this.state.userDetails },
					notes,
					this.props.metaData,
				);

				this.setState({ paymentStatus: PAYMENT_STATUS.SUCCESS });
				this.onSuccess();
			},
			prefill: {
				name:
					this.state.userDetails.firstName +
					' ' +
					this.state.userDetails.lastName,
				email: this.state.userDetails.email,
				contact: this.state.userDetails.phone,
			},
			notes: {
				email: this.state.userDetails.email,
				pan: this.state.userDetails.pan,
				passport: this.state.userDetails.passport,
				address: this.state.userDetails.address,
				...notes,
			},
			theme: {
				color: '#b71c1c',
			},
		};
	};

	handleAmountClick = (amount: string) => {
		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			event: 'form_fill',
			form_fields_name: 'amount_btn',
			usertype: localStorage.getItem('userId')
				? 'logged in'
				: 'logged out',
			userID: localStorage.getItem('userId') || null,
		});

		this.setState({
			amount,
			customAmount: false,
		});
	};

	handleFrequencyClick = (frequency: PAY_FREQUENCY) => {
		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			event: 'form_fill',
			form_fields_name: 'duration',
			usertype: localStorage.getItem('userId')
				? 'logged in'
				: 'logged out',
			userID: localStorage.getItem('userId') || null,
		});

		if (frequency === this.state.frequency) return;
		this.setState({
			frequency,
		});
		let amountList: string[];
		switch (frequency) {
			case PAY_FREQUENCY.ONCE:
				amountList = this.props.amountListOnce;
				break;
			case PAY_FREQUENCY.MONTHLY:
				amountList = this.props.amountListMonthly;
				break;
			case PAY_FREQUENCY.ANNUAL:
				amountList = this.props.amountListAnnual;
				break;
		}

		this.setState({ amount: amountList[2].toString(), amountList });
	};

	handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			event: 'form_fill',
			form_fields_name: 'amount',
			usertype: localStorage.getItem('userId')
				? 'logged in'
				: 'logged out',
			userID: localStorage.getItem('userId') || null,
		});
		const value = e.target.value;
		const decimalRegex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

		if (value === '' || decimalRegex.test(value)) {
			this.setState({ amount: e.target.value, customAmount: true });
		}
	};

	handleDetailsSubmit = async () => {
		const userId = localStorage.getItem('userId');
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'supportus_contribute_click',
			support_us_channel: 'subscription',
			support_us_duration: this.state.frequency,
			support_us_amount: this.state.amount,
			currency: this.state.currency,
			usertype: userId ? 'logged in' : 'logged out',
			userID: userId || null,
		});
		this.setState({
			paymentProcessing: true,
		});
		try {
			await this.displayRazorpayForm();
		} catch (e: any) {
			this.setState({
				paymentStatus: PAYMENT_STATUS.FAILED,
				failureReason: e.message,
			});
		}
		this.setState({
			paymentProcessing: false,
		});
	};

	inputHandler = {
		firstNameHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
			const firstName = e.target.value;
			this.setState({
				userDetails: {
					...this.state.userDetails,
					firstName,
				},
				detailsFormState: {
					...this.state.detailsFormState,
					firstName: {
						touched: true,
						valid: firstName !== '',
					},
				},
			});
		},
		lastNameHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
			const lastName = e.target.value;
			this.setState({
				userDetails: {
					...this.state.userDetails,
					lastName,
				},
				detailsFormState: {
					...this.state.detailsFormState,
					lastName: {
						touched: true,
						valid: true,
					},
				},
			});
		},
		emailHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
			const email = e.target.value.toLowerCase();
			const emailRegex =
				/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			this.setState({
				userDetails: {
					...this.state.userDetails,
					email,
				},
				detailsFormState: {
					...this.state.detailsFormState,
					email: {
						touched: true,
						valid: emailRegex.test(email),
					},
				},
			});
		},
		phoneHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
			const phone = e.target.value;
			const phoneRegex = /^[0-9]*$/;
			this.setState({
				userDetails: {
					...this.state.userDetails,
					phone,
				},
				detailsFormState: {
					...this.state.detailsFormState,
					phone: {
						touched: true,
						valid: phoneRegex.test(phone),
					},
				},
			});
		},
		panHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
			const pan = e.target.value.toUpperCase();
			const panRegex = /^[A-Z]{3}[ABCFGHLJPT][A-Z][0-9]{4}[A-Z]$/;
			this.setState({
				userDetails: {
					...this.state.userDetails,
					pan,
				},
				detailsFormState: {
					...this.state.detailsFormState,
					pan: {
						touched: true,
						valid: panRegex.test(pan),
					},
				},
			});
		},
		addressHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
			const address = e.target.value;
			// const panRegex = /^[A-Z]{3}[ABCFGHLJPT][A-Z][0-9]{4}[A-Z]$/;
			this.setState({
				userDetails: {
					...this.state.userDetails,
					address,
				},
				detailsFormState: {
					...this.state.detailsFormState,
					address: {
						touched: true,
						valid: address !== '',

					},
				},
			});
		},
		passportHandler: (e: React.ChangeEvent<HTMLInputElement>) => {
			const passport = e.target.value.toUpperCase();
			const passRegex = /^(?!^0+$)[a-zA-Z0-9]{3,20}$/;
			this.setState({
				userDetails: {
					...this.state.userDetails,
					passport,
				},
				detailsFormState: {
					...this.state.detailsFormState,
					passport: {
						touched: true,
						valid: passRegex.test(passport),
					},
				},
			});
		},
	};

	HelpText = (text: string) => {
		return (
			<div className="help-text">
				<i>{text}</i>
			</div>
		);
	};

	handleOnFocus = (form_fields_name: string) => {
		let isValid = true;
		switch (form_fields_name) {
			case 'first_name':
				isValid =
					this.state.detailsFormState.firstName.valid &&
					this.state.userDetails.firstName !== '';
				break;
			case 'last_name':
				isValid =
					this.state.detailsFormState.lastName.valid &&
					this.state.userDetails.lastName !== '';
				break;
			case 'email':
				isValid =
					this.state.detailsFormState.email.valid &&
					typeof this.state.userDetails.email !== 'undefined' &&
					this.state.userDetails.email !== '';
				break;
			case 'phone_number':
				isValid =
					this.state.detailsFormState.phone.valid &&
					this.state.userDetails.phone !== '';
				break;
			case 'PAN_number':
				isValid =
					this.state.detailsFormState.pan.valid &&
					this.state.userDetails.pan !== '';
				break;
			case 'passport_number':
				isValid =
					this.state.detailsFormState.passport.valid &&
					this.state.userDetails.passport !== '';
				break;
			case 'address':
				isValid =
					this.state.detailsFormState.address.valid &&
					this.state.userDetails.address !== '';
				break;
		}
		if (!isValid) return;
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'form_fill',
			form_fields_name,
			usertype: localStorage.getItem('userId')
				? 'logged in'
				: 'logged out',
			userID: localStorage.getItem('userId') || null,
		});
	};

	handleCustomAmtClick = () => {
		this.setState((prevState) => ({
			customAmount: !prevState.customAmount,
		}));
	};

	handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		window.dataLayer = window.dataLayer || [];

		window.dataLayer.push({
			event: 'form_fill',
			form_fields_name: 'currencyDropdown',
			usertype: localStorage.getItem('userId')
				? 'logged in'
				: 'logged out',
			userID: localStorage.getItem('userId') || null,
		});
		const currency = e.target.value;
		const defaultAmounts =
			currency === 'INR'
				? this.props.amountListOnce
				: this.props.amountListIntl;
		this.setState({
			currency,
			amountList: defaultAmounts,
			amount: currency === 'INR' ? defaultAmounts[2] : defaultAmounts[0],
			frequency: PAY_FREQUENCY.ONCE,
		});
	};

	handleDefaultCurrencyChange = (value: string) => {
		const currency = value;
		const defaultAmounts =
			currency === 'INR'
				? this.props.amountListOnce
				: this.props.amountListIntl;
		this.setState({
			currency,
			amountList: defaultAmounts,
			amount: currency === 'INR' ? defaultAmounts[2] : defaultAmounts[0],
			frequency: PAY_FREQUENCY.ONCE,
		});
	};

	isDetailsValid = () => {
		const formState = this.state.detailsFormState;

		const isValid =
			formState.firstName.valid &&
			formState.lastName.valid &&
			formState.email.valid &&
			formState.address.valid &&
			formState.phone.valid;

		return this.state.currency === 'INR'
			? isValid && (formState.pan.valid || this.props.panOptional)
			: isValid && (formState.passport.valid || this.props.panOptional);
	};

	isFormFilled = () => {
		const userDetails = this.state.userDetails;

		const isFilled =
			userDetails.firstName &&
			userDetails.email &&
			userDetails.phone &&
			(this.state.indianCitizen || this.props.hideCheckBox);

		return this.state.currency === 'INR'
			? isFilled && (userDetails.pan || this.props.panOptional)
			: isFilled && (userDetails.passport || this.props.panOptional);
	};

	isDetailsTouched = () => {
		const formState = this.state.detailsFormState;
		return (
			formState.firstName.touched ||
			formState.lastName.touched ||
			formState.email.touched ||
			formState.pan.touched ||
			formState.passport.touched ||
			formState.phone.touched
		);
	};

	handleIndianCitizenChckBx = () => {
		if (!this.state.indianCitizen) {
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: 'form_fill',
				form_fields_name: 'declaration',
				usertype: localStorage.getItem('userId')
					? 'logged in'
					: 'logged out',
				userID: localStorage.getItem('userId') || null,
			});
		}

		this.setState((prevState) => ({
			indianCitizen: !prevState.indianCitizen,
		}));
	};

	onSuccess = () => {
		const userId = localStorage.getItem('userId');

		this.props.onSuccess
			? this.props.onSuccess({
					amount: this.state.amount,
					email: this.state.userDetails.email,
					currency: this.state.currency,
					frequency: this.state.frequency,
			  })
			: alert('Payment successful!');

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'contribution_success',
			payment_method: null,
			support_us_duration: this.state.frequency,
			currency: this.state.currency,
			support_us_amount: this.state.amount,
			usertype: userId ? 'logged in' : 'logged out',
			userID: userId,
		});
	};

	SupportTheWireContent = () => {
		return (
			<div className="supportTheWire">
				<div className="supportTheWire_freq">
					<div className="supportTheWire_freq_label" id="freq_label">
						{this.props.formLabel}
					</div>
					<div
						className="supportTheWire_freqBtnGrp"
						ref={this.props.childRef}
					>
						{this.state.currency === 'INR' && (
							<>
								{this.props.frequency?.monthly && (
									<div
									className={`freq-btn${
										this.state.frequency ===
										PAY_FREQUENCY.MONTHLY
												? '_active'
												: ''
										}`}
										onClick={this.handleFrequencyClick.bind(
											this,
											PAY_FREQUENCY.MONTHLY,
										)}
									>
										Monthly
									</div>
								)}
								{this.props.frequency?.yearly && (
									<div
										className={`freq-btn${
											this.state.frequency ===
											PAY_FREQUENCY.ANNUAL
												? '_active'
												: ''
										}`}
										onClick={this.handleFrequencyClick.bind(
											this,
											PAY_FREQUENCY.ANNUAL,
										)}
										>
										Yearly
									</div>
								)}
								{this.props.frequency?.once && (
									<div
										className={`freq-btn${
											this.state.frequency === PAY_FREQUENCY.ONCE
												? '_active'
												: ''
										}`}
										onClick={this.handleFrequencyClick.bind(
											this,
											PAY_FREQUENCY.ONCE,
										)}
									>
										One-Time
									</div>
								)}
							</>
						)}
					</div>
				</div>
				<div className="supportTheWire_amt">
					<div className="supportTheWire_amt_label" id="amt_label">
						Select amount
					</div>
					<div className="supportTheWire_amtBtnGrp">
						<CurrencyDropdown
							value={this.state.currency}
							handleCurrencyChange={this.handleCurrencyChange}
						/>
						<div
							className={`amt-btn${
								!this.state.customAmount &&
								this.state.amount === this.state.amountList[0]
									? '_active'
									: ''
							}`}
							onClick={this.handleAmountClick.bind(
								this,
								this.state.amountList[0],
							)}
						>
							{this.state.amountList[0]}
						</div>
						<div
							className={`amt-btn${
								!this.state.customAmount &&
								this.state.amount === this.state.amountList[1]
									? '_active'
									: ''
							}`}
							onClick={this.handleAmountClick.bind(
								this,
								this.state.amountList[1],
							)}
						>
							{this.state.amountList[1]}
						</div>
						<div
							className={`amt-btn${
								!this.state.customAmount &&
								this.state.amount === this.state.amountList[2]
									? '_active'
									: ''
							}`}
							onClick={this.handleAmountClick.bind(
								this,
								this.state.amountList[2],
							)}
						>
							{this.state.amountList[2]}
						</div>
					</div>
					<div className="supportTheWire_amtInput">
						<input
							className="customAmtInput"
							onChange={this.handleAmountChange}
							placeholder="OR Type any amount you wish to contribute"
							value={
								this.state.customAmount ? this.state.amount : ''
							}
						></input>
					</div>
				</div>
			</div>
		);
	};

	AddContactDetailsContent = () => {
		return (
			<div className="addContactDetails">
				<div
					className="supportTheWire_freq_label"
					id="contact_details_label"
				>
					Donor Details
				</div>
				<div className="supportTheWire_detsForm">
					<div className="supportTheWire_detsForm_name">
						<div style={{ marginRight: '10px' }}>
							<input
								value={this.state.userDetails.firstName}
								onChange={this.inputHandler.firstNameHandler}
								onBlur={() => this.handleOnFocus('first_name')}
								placeholder="First name*"
								required
							></input>
							{this.state.detailsFormState.firstName.valid ||
								this.HelpText('Invalid First name')}
						</div>
						<div style={{ marginLeft: '10px' }}>
							<input
								value={this.state.userDetails.lastName}
								onChange={this.inputHandler.lastNameHandler}
								onBlur={() => this.handleOnFocus('last_name')}
								placeholder="Last name"
							></input>
							{this.state.detailsFormState.lastName.valid ||
								this.HelpText('Invalid Last name')}
						</div>
					</div>
					<input
						value={this.state.userDetails.email}
						onChange={this.inputHandler.emailHandler}
						onBlur={() => this.handleOnFocus('email')}
						placeholder="Email*"
						required
					></input>
					{this.state.detailsFormState.email.valid ||
						this.HelpText('Invalid Email')}
					<input
						value={this.state.userDetails.phone}
						onChange={this.inputHandler.phoneHandler}
						onBlur={() => this.handleOnFocus('phone_number')}
						placeholder="Phone*"
						required
					></input>
					{this.state.detailsFormState.phone.valid ||
						this.HelpText('Invalid Phone')}
					<>
						<input
							value={this.state.userDetails.pan}
							onChange={this.inputHandler.panHandler}
							onBlur={() => this.handleOnFocus('PAN_number')}
							placeholder={`PAN Number${
								this.props.panOptional ? '' : '*'
							}`}
							required={!!!this.props.panOptional}
						></input>
						{this.state.detailsFormState.pan.valid ||
							this.HelpText('Invalid PAN Number')}
					</>
					<>
						<input
							value={this.state.userDetails.address}
							onChange={this.inputHandler.addressHandler}
							onBlur={() => this.handleOnFocus('address')}
							placeholder={'Address*'}
							required
						></input>
						{this.state.detailsFormState.address.valid ||
							this.HelpText('Invalid Address')}
					</>
					{this.state.currency !== 'INR' && (
						<>
							<input
								value={this.state.userDetails.passport}
								onChange={this.inputHandler.passportHandler}
								onBlur={() =>
									this.handleOnFocus('passport_number')
								}
								placeholder={`Passport Number${
									this.props.panOptional ? '' : '*'
								}`}
								required={!!!this.props.panOptional}
							></input>
							{this.state.detailsFormState.passport.valid ||
								this.HelpText('Invalid Passport Number')}
						</>
					)}
				</div>

				{this.props.hideCheckBox || (
					<div className="supportTheWire_checkbox">
						<input
							type="checkbox"
							id="indianCitizenChckBx"
							name="indianCitizenChckBx"
							checked={this.state.indianCitizen}
							onChange={this.handleIndianCitizenChckBx}
						></input>
						<label htmlFor="indianCitizenChckBx">
							I declare that I am a citizen of India
						</label>
					</div>
				)}
				<div className="continueBtnContainer">
					<button
						className="continue-button"
						onClick={this.handleDetailsSubmit}
						disabled={
							!this.isDetailsValid() ||
							!this.isDetailsTouched() ||
							!this.isFormFilled() ||
							this.state.paymentProcessing
						}
					>
						<span>
							Contribute{' '}
							{this.state.amount === '' || `${this.state.currency} ${this.state.amount}`}{' '}{this.state.amount === '' || this.state.frequency}
						</span>
						<i className="arrow-right-icon-white"></i>
					</button>
				</div>
			</div>
		);
	};

	RBIText = () => {
		return (
			<div className="rbiText" style={{ paddingBottom: '1rem' }}>
				<ul>
					{this.props.hideTaxReceipt || (
						<li>
							All contributions are eligible for deductions under
							sec 80G of the Income Tax Act
						</li>
					)}
					<li>
						Your Donation{' '}
						{this.props.hideTaxReceipt || 'and Tax Receipt'} will be
						sent to the email id shared
					</li>
					{/* {this.props.hideTaxReceipt || (
						<li>
							If you login to The Wire using the same email shared
							your donation and tax receipt will be available in
							your user dashboard
						</li>
					)} */}
					<li>
						As per the RBI mandate, all recurring i.e Monthly and
						Yearly payments can be made using only UPI or Netbanking
						and select cards for now
					</li>
					<li>
						You can continue making One-Time payments using any
						payment mode.
					</li>
				</ul>
			</div>
		);
	};

	FailureCard = () => {
		return (
			<div className="failureCard">
				<div className="failureCard_info">
					{this.state.failureReason}
				</div>
				<div className="continueBtnContainer">
					<button
						className="failure-button"
						onClick={() => {
							this.setState({
								paymentStatus: PAYMENT_STATUS.PENDING,
							});
						}}
					>
						<span>Try Again</span>
						<i className="arrow-right-icon-white"></i>
					</button>
				</div>
			</div>
		);
	};

	SuccessCard = () => {
		return (
			<div className="SuccessCard">
				<div className="reviewAndPay_info">
					Thank you for supporting us with{' '}
					<span>₹{this.state.amount}</span>.
					<div style={{ height: '20px' }}></div>
					This amount will be charged{' '}
					<span>{this.state.frequency}</span> from your payment
					method. Your invoice will be sent to{' '}
					<span>{this.state.userDetails.email}</span>.
				</div>
			</div>
		);
	};

	render() {
		return (
			<div className="paymentContainer">
				{[
					this.SupportTheWireContent(),
					this.AddContactDetailsContent(),
					this.RBIText(),
				]}
			</div>
		);
	}
}
