import { CFormSelect } from '@coreui/react';
import './index.scss';
import '@coreui/coreui/dist/css/coreui.min.css';
import { CurrencyDropdownComponentProps, currencyList } from './types';

const CurrencyDropdown = (props: CurrencyDropdownComponentProps) => {
	return (
		<div>
			<CFormSelect
				size="lg"
				className="currencyDropdown"
				onChange={props.handleCurrencyChange}
				value={props.value}
			>
				{currencyList.map((v, i) => (
					<option className="currencyDropdown_option" key={i}>
						{v['ISO Code']}
					</option>
				))}
			</CFormSelect>
		</div>
	);
};

export default CurrencyDropdown;
