import './index.scss'
import { ThankYouCardProps } from './types'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
    RedditShareButton,
    RedditIcon
} from "react-share"

const ThankYouCard = (props: ThankYouCardProps) => {
    return (
    <div className="paymentContainer">
          <div className='paymentContainer_thankyou_icon'>
            <img src="/thank-you.svg" alt="The Wire Logo" />
          </div>
          <div className='paymentContainer_thankyou_text boldText'>
            Thank you for your contribution. Your support to The Wire helps us remain sustainable. You supported us with <span className='redText'>{props.paymentData.currency === "INR" ? "₹": props.paymentData.currency} {props.paymentData.amount}</span>. This amount will be charged {props.paymentData.frequency} from your payment method. Your invoice will be sent to <span className='redText'>{props.paymentData.email}</span>.
            <div style={{height: "2rem"}}></div>
            Help independent journalism, show your support to your friends and family by sharing:
          </div>
          <div className='paymentContainer_thankyou_socialIcons'>
              <FacebookShareButton url={"https://support.thewire.in"} title={'Help independent journalism'}> 
                  <FacebookIcon size={20} round/>
              </FacebookShareButton>
              <TwitterShareButton url={"https://support.thewire.in"} title={'Help independent journalism'}> 
                  <TwitterIcon size={20} round/>
              </TwitterShareButton>
              <LinkedinShareButton url={"https://support.thewire.in"} title={'Help independent journalism'}> 
                  <LinkedinIcon size={20} />
              </LinkedinShareButton>
              <WhatsappShareButton url={"https://support.thewire.in"} title={'Help independent journalism'}> 
                  <WhatsappIcon size={20} round/>
              </WhatsappShareButton>
              <RedditShareButton url={"https://support.thewire.in"} title={'Help independent journalism'}> 
                  <RedditIcon size={20} round/>
              </RedditShareButton>
          </div>
    </div>)
}

export default ThankYouCard;