export function loadScript(src: string) {
	return new Promise((resolve) => {
		const script = document.createElement('script');
		script.src = src;
		script.onload = () => {
			resolve(true);
		};
		script.onerror = () => {
			resolve(false);
		};
		document.body.appendChild(script);
	});
}

export const roundOffFloat = (num: number) => {
	return Math.ceil(parseFloat(num.toFixed(2)) * 100);
};

export const signInTabParams =
	'_toolbar=no, menubar=no, location=no, status=no, resizable=yes, scrollbars=yes, height=870, width=870';

export const signOutTabParams =
	'_toolbar=no, menubar=no, location=no, status=no, resizable=yes, scrollbars=yes, height=400, width=400';
