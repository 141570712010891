import { ChangeEventHandler } from 'react';

export interface CurrencyDropdownComponentProps {
	handleCurrencyChange: ChangeEventHandler<HTMLElement>;
	value: string;
}

export const currencyList = [
	{
		'Currency Name': 'United Arab Emirates Dirham',
		'ISO Code': 'AED',
	},
	{
		'Currency Name': 'Albanian lek',
		'ISO Code': 'ALL',
	},
	{
		'Currency Name': 'Armenian dram',
		'ISO Code': 'AMD',
	},
	{
		'Currency Name': 'Argentine peso',
		'ISO Code': 'ARS',
	},
	{
		'Currency Name': 'Australian dollar',
		'ISO Code': 'AUD',
	},
	{
		'Currency Name': 'Aruban florin',
		'ISO Code': 'AWG',
	},
	{
		'Currency Name': 'Barbadian dollar',
		'ISO Code': 'BBD',
	},
	{
		'Currency Name': 'Bangladeshi taka',
		'ISO Code': 'BDT',
	},
	{
		'Currency Name': 'Bermudian dollar',
		'ISO Code': 'BMD',
	},
	{
		'Currency Name': 'Brunei dollar',
		'ISO Code': 'BND',
	},
	{
		'Currency Name': 'Bolivian boliviano',
		'ISO Code': 'BOB',
	},
	{
		'Currency Name': 'Bahamian dollar',
		'ISO Code': 'BSD',
	},
	{
		'Currency Name': 'Botswana pula',
		'ISO Code': 'BWP',
	},
	{
		'Currency Name': 'Belize dollar',
		'ISO Code': 'BZD',
	},
	{
		'Currency Name': 'Canadian dollar',
		'ISO Code': 'CAD',
	},
	{
		'Currency Name': 'Swiss franc',
		'ISO Code': 'CHF',
	},
	{
		'Currency Name': 'Chinese yuan renminbi',
		'ISO Code': 'CNY',
	},
	{
		'Currency Name': 'Colombian peso',
		'ISO Code': 'COP',
	},
	{
		'Currency Name': 'Costa Rican colon',
		'ISO Code': 'CRC',
	},
	{
		'Currency Name': 'Cuban peso',
		'ISO Code': 'CUP',
	},
	{
		'Currency Name': 'Czech koruna',
		'ISO Code': 'CZK',
	},
	{
		'Currency Name': 'Danish krone',
		'ISO Code': 'DKK',
	},
	{
		'Currency Name': 'Dominican peso',
		'ISO Code': 'DOP',
	},
	{
		'Currency Name': 'Algerian dinar',
		'ISO Code': 'DZD',
	},
	{
		'Currency Name': 'Egyptian pound',
		'ISO Code': 'EGP',
	},
	{
		'Currency Name': 'Ethiopian birr',
		'ISO Code': 'ETB',
	},
	{
		'Currency Name': 'European euro',
		'ISO Code': 'EUR',
	},
	{
		'Currency Name': 'Fijian dollar',
		'ISO Code': 'FJD',
	},
	{
		'Currency Name': 'Pound sterling',
		'ISO Code': 'GBP',
	},
	{
		'Currency Name': 'Ghanian Cedi',
		'ISO Code': 'GHS',
	},
	{
		'Currency Name': 'Gibraltar pound',
		'ISO Code': 'GIP',
	},
	{
		'Currency Name': 'Gambian dalasi',
		'ISO Code': 'GMD',
	},
	{
		'Currency Name': 'Guatemalan quetzal',
		'ISO Code': 'GTQ',
	},
	{
		'Currency Name': 'Guyanese dollar',
		'ISO Code': 'GYD',
	},
	{
		'Currency Name': 'Hong Kong dollar',
		'ISO Code': 'HKD',
	},
	{
		'Currency Name': 'Honduran lempira',
		'ISO Code': 'HNL',
	},
	{
		'Currency Name': 'Croatian kuna',
		'ISO Code': 'HRK',
	},
	{
		'Currency Name': 'Haitian gourde',
		'ISO Code': 'HTG',
	},
	{
		'Currency Name': 'Hungarian forint',
		'ISO Code': 'HUF',
	},
	{
		'Currency Name': 'Indonesian rupiah',
		'ISO Code': 'IDR',
	},
	{
		'Currency Name': 'Israeli new shekel',
		'ISO Code': 'ILS',
	},
	{
		'Currency Name': 'Indian rupee',
		'ISO Code': 'INR',
	},
	{
		'Currency Name': 'Jamaican dollar',
		'ISO Code': 'JMD',
	},
	{
		'Currency Name': 'Kenyan shilling',
		'ISO Code': 'KES',
	},
	{
		'Currency Name': 'Kyrgyzstani som',
		'ISO Code': 'KGS',
	},
	{
		'Currency Name': 'Cambodian riel',
		'ISO Code': 'KHR',
	},
	{
		'Currency Name': 'Cayman Islands dollar',
		'ISO Code': 'KYD',
	},
	{
		'Currency Name': 'Kazakhstani tenge',
		'ISO Code': 'KZT',
	},
	{
		'Currency Name': 'Lao kip',
		'ISO Code': 'LAK',
	},
	{
		'Currency Name': 'Lebanese pound',
		'ISO Code': 'LBP',
	},
	{
		'Currency Name': 'Sri Lankan rupee',
		'ISO Code': 'LKR',
	},
	{
		'Currency Name': 'Liberian dollar',
		'ISO Code': 'LRD',
	},
	{
		'Currency Name': 'Lesotho loti',
		'ISO Code': 'LSL',
	},
	{
		'Currency Name': 'Moroccan dirham',
		'ISO Code': 'MAD',
	},
	{
		'Currency Name': 'Moldovan leu',
		'ISO Code': 'MDL',
	},
	{
		'Currency Name': 'Macedonian denar',
		'ISO Code': 'MKD',
	},
	{
		'Currency Name': 'Myanmar kyat',
		'ISO Code': 'MMK',
	},
	{
		'Currency Name': 'Mongolian tugrik',
		'ISO Code': 'MNT',
	},
	{
		'Currency Name': 'Macanese pataca',
		'ISO Code': 'MOP',
	},
	{
		'Currency Name': 'Mauritian rupee',
		'ISO Code': 'MUR',
	},
	{
		'Currency Name': 'Maldivian rufiyaa',
		'ISO Code': 'MVR',
	},
	{
		'Currency Name': 'Malawian kwacha',
		'ISO Code': 'MWK',
	},
	{
		'Currency Name': 'Mexican peso',
		'ISO Code': 'MXN',
	},
	{
		'Currency Name': 'Malaysian ringgit',
		'ISO Code': 'MYR',
	},
	{
		'Currency Name': 'Namibian dollar',
		'ISO Code': 'NAD',
	},
	{
		'Currency Name': 'Nigerian naira',
		'ISO Code': 'NGN',
	},
	{
		'Currency Name': 'Nicaraguan cordoba',
		'ISO Code': 'NIO',
	},
	{
		'Currency Name': 'Norwegian krone',
		'ISO Code': 'NOK',
	},
	{
		'Currency Name': 'Nepalese rupee',
		'ISO Code': 'NPR',
	},
	{
		'Currency Name': 'New Zealand dollar',
		'ISO Code': 'NZD',
	},
	{
		'Currency Name': 'Peruvian sol',
		'ISO Code': 'PEN',
	},
	{
		'Currency Name': 'Papua New Guinean kina',
		'ISO Code': 'PGK',
	},
	{
		'Currency Name': 'Philippine peso',
		'ISO Code': 'PHP',
	},
	{
		'Currency Name': 'Pakistani rupee',
		'ISO Code': 'PKR',
	},
	{
		'Currency Name': 'Qatari riyal',
		'ISO Code': 'QAR',
	},
	{
		'Currency Name': 'Russian ruble',
		'ISO Code': 'RUB',
	},
	{
		'Currency Name': 'Saudi Arabian riyal',
		'ISO Code': 'SAR',
	},
	{
		'Currency Name': 'Seychellois rupee',
		'ISO Code': 'SCR',
	},
	{
		'Currency Name': 'Swedish krona',
		'ISO Code': 'SEK',
	},
	{
		'Currency Name': 'Singapore dollar',
		'ISO Code': 'SGD',
	},
	{
		'Currency Name': 'Sierra Leonean leone',
		'ISO Code': 'SLL',
	},
	{
		'Currency Name': 'Somali shilling',
		'ISO Code': 'SOS',
	},
	{
		'Currency Name': 'South Sudanese pound',
		'ISO Code': 'SSP',
	},
	{
		'Currency Name': 'Salvadoran colón',
		'ISO Code': 'SVC',
	},
	{
		'Currency Name': 'Swazi lilangeni',
		'ISO Code': 'SZL',
	},
	{
		'Currency Name': 'Thai baht',
		'ISO Code': 'THB',
	},
	{
		'Currency Name': 'Trinidad and Tobago dollar',
		'ISO Code': 'TTD',
	},
	{
		'Currency Name': 'Tanzanian shilling',
		'ISO Code': 'TZS',
	},
	{
		'Currency Name': 'United States dollar',
		'ISO Code': 'USD',
	},
	{
		'Currency Name': 'Uruguayan peso',
		'ISO Code': 'UYU',
	},
	{
		'Currency Name': "Uzbekistani so'm",
		'ISO Code': 'UZS',
	},
	{
		'Currency Name': 'Yemeni rial',
		'ISO Code': 'YER',
	},
	{
		'Currency Name': 'South African rand',
		'ISO Code': 'ZAR',
	},
];
