const baseUrl = process.env.REACT_APP_BASE_URL;
const SSOUrl = process.env.REACT_APP_SSO_URL;
const SSOClientId = process.env.REACT_APP_SSO_CLIENT_ID;
const SSORedirectUrl = process.env.REACT_APP_SSO_REDIRECT_URL;
const RPAY_KEY_ID = process.env.REACT_APP_RPAY_KEY_ID;
const url = process.env.REACT_APP_URL;

const theWireconfig = {
	site_id: 'tw',
	socialLogin: {
		facebook: '181947605670928',
	},
	homeContainer: {
		categories: ['CULTURE', 'EXTERNAL-AFFAIRS'],
	},
	ipRegistryKey: process.env.REACT_APP_IP_KEY || 'null',
	profile: {
		editPage: {
			headerTitle: 'Manage Subscriptions',
			headerDescription:
				'Subscribe to receive stories directly into your inbox',
		},
	},
	subscription: {
		title: 'join our mailing list',
		description:
			'Subscribe to email from your favourite sections, and receive stories directly into your inbox',
		newslettersTitle: 'newsletters from the wire',
		formSuccess: 'Thank you! Please check your inbox to confirm',
	},
	header: {
		languageUrls: [
			{
				name: 'हिंदी',
				url: 'http://thewirehindi.com/',
			},
			{
				name: 'मराठी',
				url: 'https://marathi.thewire.in/',
			},
			{
				name: 'اردو',
				url: 'http://thewireurdu.com/',
			},
		],
		donateLink: 'https://support.thewire.in/',
		ownyournewsLink: 'https://thewire.in/ownyournews',
		subscribeLink: 'https://thewire.in/newsletter',
		sso: {
			signIn: `${SSOUrl}/sso/signin?clientid=${SSOClientId}&redirecturl=${SSORedirectUrl}`,
			signInCheck: `${SSOUrl}/sso/signin?clientid=${SSOClientId}&redirecturl=${SSORedirectUrl}&initalSignIn=true`,
			redirectUrl: `${SSORedirectUrl}`,
			profile: `${SSOUrl}/sso/profile`,
			changePassword: `${SSOUrl}/sso/change-password`,
			newsletters: `${SSOUrl}/sso/newsletters`,
			unsubscribe: `${SSOUrl}/sso/newsletters/unsubscribe`,
			connectGoogle: `${SSOUrl}/sso/auth/preconnect-google`,
			connectYoutube: `${SSOUrl}/sso/auth/preconnect-youtube`,
			disconnectGoogle: `${SSOUrl}/sso/auth/disconnect-google`,
			disconnectYoutube: `${SSOUrl}/sso/auth/disconnect-youtube`,
			authMethods: `${SSOUrl}/sso/auth/methods`,
			paymentHistory: `${SSOUrl}/sso/auth/payment-history`,
			paymentHistoryLocal: `${SSOUrl}/payment-history`,
			paymentHistoryRefresh: `${SSOUrl}/payment-history/update`,
			invoice: `${SSOUrl}/sso/auth/80g`,
			invoiceLocal: `${SSOUrl}/payment/80G`,
			signout: `${SSOUrl}/sso/signout?clientid=${SSOClientId}&redirecturl=${SSORedirectUrl}`,
			createRpayOrder: `${SSOUrl}/payment/order`,
			createRpaySubscription: `${SSOUrl}/payment/subscription`,
			paymentSuccessHandler: `${SSOUrl}/payment/success`,
			fetchRpaySubscriptionDetails: `${SSOUrl}/payment/subscription/rpay`,
		},
	},
	social: {
		facebook: 'https://facebook.com/thewire.in',
		instagram: 'https://www.instagram.com/thewirein/',
		twitter: 'https://twitter.com/thewire_in?lang=en',
		youtube: 'https://www.youtube.com/c/TheWireNews?sub_confirmation=1',
		twitterhandleurl: 'https://twitter.com',
	},
	aboutUs: {
		editorsNote: 'Note from the founding editors',
		howWeAreFunded: 'How The Wire is funded',
		meetTheTeam: 'Meet the Team',
		workWithUs: 'Current Openings',
		contactUs: 'Contact Us',
		ombudsPerson: 'ombudsperson',
		digiPub: 'digipub',
		whoOwns: 'Who Owns The Wire',
		whistleblowersPolicy: 'Whistleblowers Policy',
		contributeText:
			'To contribute an article to The Wire or enquire about syndication, please write to <a href="mailto:editorial@thewire.in">editorial@thewire.in</a>',
		subscribeText: 'Get all stories delivered to your inbox',
	},
	baseUrl: baseUrl,
	url,
	RPAY_KEY_ID,
};

export default theWireconfig;
